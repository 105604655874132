.search-field {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	width: 100%;

	.search-field__input {
		border: none;
		padding: 6px 4px;
		border-bottom: 2px solid;
		border-bottom-color: #c4c4c4;
		color: #c4c4c4;
		transition:
			border-bottom-color 0.3s ease,
			color 0.3s ease;
		outline: none;
		display: block;
		position: relative;
		width: 100%;

		&.search-field__input--active {
			color: black;
			border-bottom-color: black;
		}
		&:focus {
			color: black;
			border-bottom-color: black;
		}
	}

	.search-field__icon-container {
		height: 30px;
		width: 20px;
		opacity: 0.3;
		display: flex;
		align-items: end;
		transition: opacity ease-in 0.2s;
		margin-left: 8px;

		&.search-field__icon-container--active {
			opacity: 1;
		}
	}

	.search-field__icon {
		height: 20px;
		width: 20px;
		display: block;
		cursor: pointer;
	}
}
