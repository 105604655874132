@import '../../styles/media.scss';

.notifications__button {
	height: 60px;
	width: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	&__new {
		position: absolute;
		top: 14px;
		right: 12px;
	}
}

.notifications__no {
	font-size: small;
	color: gray;
}

.notification {
	display: flex;
	align-items: flex-start;
	width: 360px;
	position: relative;

	@include media(mobile, wide-mobile) {
		width: 100%;
		max-width: 500px;
	}

	&__new {
		position: absolute;
		top: 0;
		left: 0;
	}

	&__content {
		display: flex;
		flex-direction: column;
		margin-left: 10px;
		text-align: start;
		font-size: small;

		&__datetime {
			margin-top: 10px;
			color: gray;
		}

		&__message {
			&--system {
				a {
					text-decoration: underline;
				}
			}
		}
	}

	&__target {
		margin-left: 40px;
	}

	&__source {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		&__item {
			margin-bottom: -32px;

			&--0 {
				z-index: 1;
			}

			&--1 {
				z-index: 2;
			}

			&--2 {
				z-index: 3;
			}

			&--3 {
				z-index: 4;
			}

			&--number {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background-color: white;
				color: black;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: small;
			}
		}
	}
}
