@import '../../styles/media.scss';

.settings {
	&__menu {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		padding-top: 80px;
		padding-right: 60px;

		@include media(mobile) {
			padding-right: 0;
		}
	}

	&__menu-option {
		text-align: start;
		font-weight: 600;
		padding: 20px 20px;
		color: gray;
		transition: color 0.2s ease-in;

		&:hover {
			color: black;
		}

		&--active {
			color: white;
			cursor: default;
			background-color: black;

			&:hover {
				color: white;
			}
		}
	}

	.settings-page {
		&__title {
			text-align: start;
		}
	}
}
