.icon {
	display: inline-block;

	&__img {
		height: 100%;
		width: 100%;
		display: block;

		&--round {
			border-radius: 50%;
		}
	}
}
