.emoji-picker {
	display: flex;
	flex-direction: column;
	width: 310px;
	max-height: 300px;
	padding: 10px 16px;
	background-color: black;
	border-radius: 4px;
	overflow-y: scroll;

	.emoji-picker__list-title {
		color: #eeeeee;
		margin: 0;
		margin-bottom: 4px;
		text-align: start;
		font-size: small;
		font-weight: 600;
	}

	.emoji-picker__emoji-list {
		margin-bottom: 10px;
		text-align: start;

		.emoji-picker__emoji {
			cursor: pointer;
			display: inline-block;
			border-radius: 2px;

			&:hover {
				background-color: #353535;
			}
		}
	}
}
