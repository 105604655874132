@import '../../../styles/media.scss';

.full-cart-popup {
	position: relative;
	background-color: white;
	max-width: 900px;
	min-width: 700px;
	max-height: 94vh;
	overflow-y: auto;

	@include media(mobile, wide-mobile) {
		max-width: 100vw;
		max-height: 100vh;
		min-width: 0;
	}

	&__top-color-line {
		position: absolute;
		top: 0;
		width: 100%;
		height: 5px;
		transition: opacity ease-in 3s;
	}

	&__content-container {
		padding: 40px 40px;
		padding-right: 60px;

		@include media(mobile, wide-mobile) {
			padding: 40px 20px;
		}
	}
}
