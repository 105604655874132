.filter {
	&__no-options {
		color: lightgray;
		font-size: small;
	}

	&__header {
		margin: 10px 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;

		&__title {
			text-align: left;
			color: #9e9e9e;
			margin: 0;
		}

		&__clear {
			font-size: x-small;
			font-weight: 300;
			color: #a1a1a1;
			cursor: pointer;

			&:hover {
				color: #5b5b5b;
			}
		}
	}

	&__search {
		margin-bottom: 10px;
	}
}

.filter__options {
	text-align: start;
}

.filter-block {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding-right: 40px;

	.filter-block__item {
		margin-bottom: 40px;
		width: 100%;
	}
}

.filter-option {
	display: inline-block;
	margin-right: 8px;
	margin-bottom: 8px;
	padding: 6px 14px;
	border-radius: 18px;
	cursor: pointer;
	font-weight: 500;
	background-color: #f1f1f1;
	transition: background-color 0.3s ease;
	font-size: 14px;

	&:hover {
		background-color: #ebebeb;
	}

	.filter-option__items-count {
		color: #636363;
		font-size: 12px;
		margin-left: 6px;
	}

	&.filter-option-picked {
		color: white;
		background-color: #1f1f1f;

		&:hover {
			background-color: black;
		}

		.filter-option__items-count {
			color: #bdbdbd;
		}
	}
}

.filter__all-options {
	display: inline-block;
	font-size: small;
	color: gray;
	cursor: pointer;
	transition: color 0.3s ease;

	&:hover {
		color: black;
	}
}
