.user-info-mini {
	display: flex;
	flex-direction: column;

	&__info {
		display: flex;
		align-items: flex-end;
	}

	&__username {
		margin: 0;
		margin-left: 10px;
		font-size: larger;
	}

	&__instruments {
		text-align: start;
		margin-left: 50px;
	}
}
