.not-found {
	height: 60vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&__404 {
		font-size: 200px;
	}
}
