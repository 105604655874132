$breakpoints-min: (
	mobile: 0px,
	wide-mobile: 680px,
	desktop: 1280px,
	wide-desktop: 1700px,
);

$breakpoints-max: (
	mobile: 679px,
	wide-mobile: 1279px,
	desktop: 1699px,
	wide-desktop: 9000px,
);

@mixin media($keys...) {
	@each $key in $keys {
		@media (min-width: map-get($breakpoints-min, $key)) and (max-width: map-get($breakpoints-max, $key)) {
			@content;
		}
	}
}
