.form-input {
	&__error {
		color: #cc1c2a;
		font-size: small;
		margin-top: 6px;
	}

	&__label {
		font-size: x-small;
		color: grey;
		transition: opacity ease 0.2s;
	}
}
