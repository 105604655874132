@import '../../styles/media.scss';

.full-cart-page__container {
	padding: 70px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;

	@include media(mobile, wide-mobile) {
		padding: 70px 20px;
	}
}
