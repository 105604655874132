@import '../../styles/media.scss';

.popup-container__background {
	z-index: 1000;
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: #00000050;

	&--no {
		background: none;
	}

	.popup-container {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-height: 94vh;
		max-width: 100vw;

		@include media(mobile, wide-mobile) {
			width: 100vw;
			max-height: 100vh;
		}

		&__content {
			position: relative;
		}
	}
}
