.vcart__container {
	width: 200px;
	position: relative;

	.vcart__color_background {
		position: absolute;
		top: 0;
		left: 0;
		width: 200px;
		z-index: 1;
		transition: height 0.2s ease;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	.vcart {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		transition: heigh 0.3s ease;
		width: 200px;
		top: 0;
		height: 100%;
		white-space: normal;

		.vcart__image_container {
			margin-top: 12px;
			height: 200px;
			width: 180px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;
			z-index: 10;

			.vcart__image {
				max-width: 100%;
				max-height: 100%;
				display: block;
				border-radius: 4px;
				cursor: pointer;
			}
		}

		.vcart__title_container {
			padding: 0 12px;
			z-index: 10;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.vcart__title {
				width: 180px;
				text-align: center;
				font-size: 16px;
				font-family: 'Raleway';
				font-weight: 400;
				margin: 20px 0 4px 0;
				cursor: pointer;
				word-break: break-word;
			}

			.vcart__rate {
				width: 180px;
				text-align: center;
				font-size: 13px;
				font-family: 'Raleway';
				font-weight: 400;
				color: gray;
				margin: 3px 0 18px 0;
				z-index: 10;
				cursor: pointer;
				transition: color 0.3s ease-in;

				&:hover {
					color: #3d3d3d;
				}
			}
		}
	}
}
