.auth-page {
	margin-top: 60px;
	height: 60vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&__change_step {
		margin-top: 16px;
	}

	.text {
		margin-top: 20px;
		&--small {
			font-size: small;
			color: gray;
		}
	}
}
