@import '../../../styles/media.scss';

.subscriptions-popup {
	position: relative;
	background-color: white;
	max-width: 900px;
	min-width: 500px;
	max-height: 94vh;
	overflow-y: auto;
	padding: 0px 40px;

	@include media(mobile, wide-mobile) {
		padding: 0px 20px;
		max-width: calc(100vw - 20px);
		min-width: calc(100vw - 20px);
	}

	&__subscription {
		margin-top: 20px;
	}

	&__loading {
		height: 20px;
	}
}
