.events {
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 10000;
	padding-right: 20px;
	padding-bottom: 20px;
	display: flex;
	flex-direction: column;
	max-height: 94vh;
	overflow-y: scroll;

	.event {
		width: 300px;
		text-align: start;
		color: #252525;
		padding: 12px 18px;
		border-radius: 6px;
		font-size: small;
		font-weight: 500;
		position: relative;
		margin-top: 6px;

		&--error {
			background-color: #ffcaca;
			border-left: solid 4px #ff3b3b;
		}

		&--success {
			background-color: #ceffca;
			border-left: solid 4px #69ff3b;
		}
	}
}
