.carts-grid {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex: 0 1 auto;
	flex-direction: row;
	flex-wrap: wrap;

	&__column {
		margin-right: 10px;
		display: flex;
		flex-direction: column;
	}

	&__element {
		display: inline-block;
	}
}
