@import '../../styles/media.scss';

.page-with-side-panel {
	display: flex;
	flex-direction: row;
	margin-top: 60px;

	&__content {
		@include media(mobile, wide-mobile) {
			width: calc(100vw - 30px);
		}

		@include media(desktop) {
			width: 950px;
		}

		@include media(wide-desktop) {
			width: 1210px;
		}
	}
}
