.reacted-users-tooltip {
	padding: 10px 10px;
	background-color: #000000d7;
	display: flex;
	align-items: center;
	border-radius: 4px;

	.reacted-users-tooltip__reacted-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 34px;
		min-width: 34px;
		margin: 0 4px;
		border-radius: 50%;
		background-color: #ffffff1f;

		.reacted-users-tooltip__show-all-users-reacted {
			margin: 0;
			color: #ffffff;
			font-weight: 700;
			font-size: small;
		}
	}

	&:after {
		content: '';
		position: absolute;
		border-width: 4px;
		border-style: solid;
		top: 100%;
		left: 50%;
		transform: translate(-50%, 0);
		border-color: #000000d7 transparent transparent transparent;
	}
}
