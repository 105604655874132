.bubble-button {
	border: none;
	border-radius: 18px;
	background: black;
	padding: 6px 16px;
	font-size: 18px;
	cursor: pointer;
	color: #eeeeee;
	transition: color 0.3s ease-in-out;

	&:hover {
		color: white;
	}

	&:disabled {
		cursor: not-allowed;
		background: lightgrey;
	}

	&--loading {
		cursor: default;
		background-image: linear-gradient(
			90deg,
			#000000 0%,
			#303030 40%,
			#4e4e4e 49%,
			#4e4e4e 51%,
			#303030 60%,
			#000000 100%
		) !important;
		animation: loading 5s linear infinite;
		@keyframes loading {
			to {
				background-position: 20vw;
			}
		}
	}

	&--small {
		padding: 4px 10px;
		font-size: small;
		font-weight: 500;
	}
}
