.secondary-button {
	cursor: pointer;
	color: grey;
	text-decoration: underline grey dotted;
	font-size: small;

	&:hover {
		color: black;
		text-decoration: underline black dotted;
	}
}
