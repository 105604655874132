.form-text-input {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	&__label {
		font-size: x-small;
		color: grey;
		transition: opacity ease 0.2s;
	}

	&__input {
		width: 100%;
		font-size: large;
		border: none;
		border-bottom: solid #e0e0e0 1px;
		outline: none;
		cursor: text;
	}

	&__text {
		resize: none;
		border: none;
		outline: none;
		border-radius: 10px;
		background-color: #fafafa;
		padding: 4px 8px;
		width: 100%;
		font-family: inherit;
		font-size: small;
		min-height: 20px;
	}

	&__error {
		color: #cc1c2a;
		font-size: small;
		margin-top: 6px;
	}
}
