.cart-control__icon-container {
	max-height: 20px;
	width: 20px;
	opacity: 0.3;
	transition: opacity ease-in 0.2s;
	margin-bottom: 4px;

	&:hover {
		opacity: 0.5;
	}
}

.cart-control__icon {
	height: 100%;
	width: 100%;
	display: block;
	cursor: pointer;
}
