.emoji {
	display: inline-flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;

	&--default {
		height: 30px;
		width: 30px;
		padding: 4px;
		font-size: 28px;
	}

	&--small {
		height: 16px;
		width: 16px;
		font-size: 16px;
	}

	&__img {
		max-width: 100%;
		max-height: 100%;
		display: block;
	}
}
