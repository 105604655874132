@import '../../styles/media.scss';

.dropdown-container {
	position: relative;

	@include media(mobile, wide-mobile) {
		position: static;
	}
}

.toggle {
	background: white;
	color: #4c4c4c;
	transition:
		color 0.3s ease,
		background-color 0.3s ease;

	&--toggled {
		background-color: black;
		color: white;
	}
}

.dropdown-list {
	position: absolute;
	top: 60px;
	right: 0;
	min-width: 200px;
	opacity: 0;
	visibility: hidden;
	transition:
		opacity 0.3s ease,
		visibility 0.3s;
	background-color: black;
	overflow-y: scroll;
	max-height: 500px;

	&::-webkit-scrollbar {
		display: none;
	}

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	@include media(mobile, wide-mobile) {
		width: 100vw;
		max-height: calc(100vh - 60px);
	}

	&--toggled {
		opacity: 1;
		visibility: visible;
	}
}

.dropdown-item {
	color: white;
	font-size: large;
	font-weight: 500;
	padding: 20px 30px;
	transition: background-color 0.1s linear;

	@include media(mobile, wide-mobile) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&--only-mobile {
		display: none;

		@include media(mobile, wide-mobile) {
			display: block;
		}
	}
}

.dropdown-item:hover {
	background-color: #363636;
}
