.image {
	display: flex;
	justify-content: center;

	&__img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.loader {
	position: relative;
	width: 191px;
}

@keyframes ldio-l8fyn3jmar {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.ldio-l8fyn3jmar > div > div {
	position: absolute;
	border-radius: 50%;
}
.ldio-l8fyn3jmar > div > div:nth-child(1) {
	top: 68.75999999999999px;
	left: 68.75999999999999px;
	width: 53.480000000000004px;
	height: 53.480000000000004px;
	background: none;
}
.ldio-l8fyn3jmar > div > div:nth-child(2) {
	top: 68.75999999999999px;
	left: 90.68679999999999px;
	width: 9.626400000000002px;
	height: 9.626400000000002px;
	background: #e0e0e0;
	animation: ldio-l8fyn3jmar 1s linear infinite;
	transform-origin: 4.813200000000001px 26.740000000000002px;
}
.loadingio-spinner-disk-23pll9qqddb {
	width: 191px;
	height: 191px;
	display: inline-block;
	overflow: hidden;
	background: none;
	position: absolute;
	top: 0;
	left: 0;
}
.ldio-l8fyn3jmar {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(1);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}
.ldio-l8fyn3jmar div {
	box-sizing: content-box;
}
