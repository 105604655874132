@import '../../../styles/media.scss';

.profile {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 60px;
	padding: 0 20px;
	cursor: pointer;

	@include media(mobile) {
		padding: 0;
		padding: 6px;
	}

	@include media(mobile, wide-mobile) {
		pointer-events: none;
		cursor: default;
	}

	&__avatar {
	}
}

.profile__username {
	font-weight: 700;
	font-size: large;
	margin-left: 10px;

	@include media(mobile) {
		display: none;
	}
}

.profile-dropdown__item {
	cursor: pointer;
}
