@import '../../../../styles/media.scss';

.collection-control {
	position: relative;
	background-color: white;
	max-width: 770px;
	max-height: 94vh;
	overflow-y: auto;

	@include media(mobile, wide-mobile) {
		max-width: none;
		max-height: 100vh;
	}

	&__form {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		padding: 25px;
	}

	&__form_content {
		margin-bottom: 20px;
		margin-top: 10px;
	}

	&__input-block {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 30px;
	}

	&__input-error {
		color: #cc1c2a;
		font-size: small;
		margin-top: 6px;
	}

	&__form-controls {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: center;
		width: 100%;
	}

	&__form-submit {
		border: none;
		border-radius: 18px;
		background: black;
		padding: 6px 16px;
		font-size: 18px;
		cursor: pointer;
		color: #eeeeee;
		transition: color 0.3s ease-in-out;

		&:hover {
			color: white;
		}

		&--loading {
			cursor: default;
			background-image: linear-gradient(
				90deg,
				#000000 0%,
				#303030 40%,
				#4e4e4e 49%,
				#4e4e4e 51%,
				#303030 60%,
				#000000 100%
			);
			animation: loading 5s linear infinite;
			@keyframes loading {
				to {
					background-position: 20vw;
				}
			}
		}
	}
}
