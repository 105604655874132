.tag-bubble {
	height: 19px;
	display: inline-flex;
	align-items: center;
	padding: 8px 16px;
	border-radius: 18px;
	cursor: pointer;
	font-weight: 500;
	background-color: #f1f1f1;
	transition: background-color 0.3s ease;
	color: #9e9e9e;

	&--small {
		height: 17px;
		font-size: small;
		padding: 6px 12px;
		border-radius: 14px;
	}

	&--no-background {
		background: none;
	}

	&--disabled {
		cursor: default;
	}

	&:hover {
		background-color: #ebebeb;
	}

	&--picked {
		color: white;
		background-color: #1f1f1f;

		&:hover {
			background-color: black;
		}

		.tag-bubble__info {
			color: #bdbdbd;
		}
	}

	&__children {
		display: flex;
		margin-right: 6px;
	}
}
