@import '../../../styles/media.scss';

.news-feed-cart {
	display: flex;
	align-items: flex-start;
	position: relative;

	@include media(mobile) {
		flex-direction: column-reverse;
	}

	@include media(wide-mobile) {
		justify-content: center;
	}

	&__cart {
		@include media(mobile) {
			width: 100%;
		}
	}

	&__cart-info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		height: 100%;

		@include media(mobile) {
			width: 100%;
			flex-direction: row-reverse;
			padding-left: 8px;
		}

		&__block {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			@include media(mobile) {
				flex-direction: row;
			}
		}

		&__item {
			margin-bottom: 6px;
		}

		&__date-created {
			color: #6d6d6d;
			font-size: x-small;
			margin-top: 6px;
			margin-left: 6px;
			margin-bottom: 30px;

			@include media(mobile) {
				margin-bottom: 0;
			}
		}
	}

	&__new {
		margin-top: 9px;
		margin-left: 10px;
		display: flex;
		width: 6px;

		&--my-cart {
			opacity: 0.3;
		}

		@include media(mobile) {
			margin: 0;
			position: absolute;
			top: 0;
		}
	}
}
