.cart-tags-input {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	&__error {
		color: #cc1c2a;
		font-size: small;
		margin-top: 6px;
	}
}

.cart-tags-input__label {
	font-size: x-small;
	color: grey;
	transition: opacity ease 0.2s;
}

.cart-tags-input__input {
	font-size: large;
	border: none;
	border-bottom: solid #e0e0e0 1px;
	outline: none;
	text-align: start;
}

.cart-tags-input__option {
	display: inline-block;
	margin-right: 6px;
	margin-bottom: 6px;
}

.cart-tags-input__tags-input-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.cart-tags-input__tags-input {
	text-align: start;
}

.cart-tags-input__tags-possible {
	text-align: start;
	margin-top: 6px;
	max-height: 68px;
	overflow-y: auto;
}

.cart-tags-input__tags-selected {
	text-align: start;
	margin-top: 10px;
}
