@import '../../styles/media.scss';

.user-info-block {
	display: flex;
	flex-direction: column;

	.user-info-main {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;

		@include media(mobile) {
			max-width: 100vw;
			flex-direction: column;
			align-items: center !important;
		}

		.user-info {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			@include media(mobile) {
				margin-bottom: 10px;
			}

			&__username {
				font-size: 46px;
				cursor: pointer;
				margin: 0;

				@include media(mobile) {
					font-size: 30px !important;
					max-width: calc(100vw - 60px);
					line-break: anywhere;
				}
			}

			&__avatar {
				border-radius: 50%;
				height: 55px;
				cursor: pointer;
				margin-left: 14px;
			}
		}
	}

	.user-info-subscribers {
		display: flex;
		margin-top: 20px;

		&__block {
			margin-right: 50px;
			position: relative;
		}

		&__label {
			margin-top: 10px;
			color: gray;
			cursor: pointer;
			text-decoration: underline #e6e6e6;
			font-size: larger;

			&:hover {
				text-decoration: underline;
			}
		}

		&__count {
			font-size: small;
			margin-left: 6px;
			color: gray;
			position: absolute;
			top: 0;
		}
	}
}
