.invite-settings {
	padding: 20px 0;

	&__element {
		&--margin-bot {
			margin-bottom: 40px;
		}

		&__text {
			text-align: start;

			&--small {
				font-size: small;
				color: gray;
			}
		}
	}
}
