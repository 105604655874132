.no-content {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 200px;

	&__text {
		color: #e0e0e0;
	}
}
