@import '../../styles/media.scss';

.user-page {
	width: 100%;

	&__user-info {
		margin-top: 50px;
		width: 100%;
	}

	&__create-collection {
		width: 100%;
		height: 50px;
		margin-top: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #f8f8f8;
		cursor: pointer;
		border-radius: 8px;
		transition: background-color 0.5s ease-in-out;

		.user-page__create-collection_button {
			font-size: large;
			font-weight: 500;
			color: #8a8a8a;
			transition: color 0.5s ease-in-out;
		}

		&:hover {
			background-color: #e6e6e6;

			.user-page__create-collection_button {
				color: #414141;
			}
		}
	}

	&__collections {
		margin-top: 50px;

		.collection {
			margin: 20px 0;
			max-width: 920px;

			@include media(mobile) {
				margin-left: 10px;
			}

			@include media(wide-mobile) {
				margin-left: 20px;
			}

			&__instruments {
				display: flex;
			}

			&__instrument {
				cursor: pointer;
				font-weight: 600;
				transition: color 0.2s ease-in;
				color: lightgrey;
				margin: 0;
				margin-left: 10px;

				&:hover {
					color: black;
				}
			}

			.collection__carts {
				min-height: 320px;
				max-width: 920px;
				display: flex;
				overflow-y: hidden;
				overflow-x: scroll;
				scrollbar-width: none;

				.collection__vcart_container {
					margin-right: 30px;
					display: inline-block;

					.collection__util-cart {
						width: 160px;
						height: 200px;
						margin-top: 16px;
						margin-right: 40px;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						border-radius: 30px;
						background-color: #f8f8f8;
						color: #c4c4c4;
						transition: background-color 0.5s ease-in-out;
						cursor: pointer;

						&:hover {
							color: white;
							background-color: black;
						}

						.collection__util-cart-number {
							font-size: 70px;
							font-weight: 700;
							margin: 0;
						}
					}
				}
			}
		}
	}
}
