@import '../../styles/media.scss';

.news {
	width: 100%;
	margin-top: 20px;

	&__cart_container {
		margin-bottom: 120px;
		max-width: 800px;
		margin-right: 20px;

		@include media(mobile, wide-mobile) {
			max-width: 100vw;
			margin-right: 0;
		}
	}
}
