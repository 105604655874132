.emoji__container {
	position: relative;

	.emoji__reacted-users-tooltip {
		position: absolute;
		bottom: 26px;
		left: 50%;
		transform: translate(-50%, 0);
		visibility: hidden;
		opacity: 0;
		transition: 0.25s all ease;
		transition-delay: 0.1s;
	}

	&:hover,
	&:active {
		.emoji__reacted-users-tooltip {
			visibility: visible;
			opacity: 1;
			transition: 0.25s all ease;
			transition-delay: 0.4s;
			bottom: 34px;
		}
	}

	.emoji__button {
		border-radius: 14px;
		border: #f3f3f3 solid 1px;
		background-color: #f3f3f3;
		padding: 4px 6px;
		display: flex;
		align-items: center;
		cursor: pointer;
		transition: background-color 0.2s ease-in;

		&:hover {
			background-color: #ffffff;
			border: #c5c5c5 solid 1px;
		}
		&.emoji__button--reacted {
			border: #dddddd solid 2px;
			background-color: #f3f3f3;
		}

		.emoji__img-container {
			margin-right: 6px;
		}

		.emoji__count {
			font-size: small;
			color: gray;
		}

		&.emoji__container--reacted {
			.emoji__count {
				font-weight: 600;
			}
		}
	}
}
