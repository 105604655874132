@import '../../styles/media.scss';

header {
	height: 60px;

	.header {
		&__count {
			font-size: x-small;
		}

		&__home-icon {
			width: 40px;
		}

		&__item {
			height: 100%;

			&--home {
				@include media(mobile) {
					display: none;
				}
			}
		}
	}
}
