.subscriptions {
	&__owner-info {
		margin-bottom: 50px;
	}

	&__title-block {
		color: lightgray;
		font-size: large;
		text-align: start;
	}

	&__users {
	}

	&__search {
		width: 90%;
	}

	&__user {
		margin-right: 30px;
		margin-bottom: 20px;
		display: flex;
	}
}
