@import '../../styles/media.scss';

.toolbar-container {
	position: fixed;
	top: 0;
	transition: top 0.1s ease-out;
	z-index: 999;
	display: flex;
	justify-content: center;

	@include media(mobile, wide-mobile) {
		width: 100vw;
		left: 0;
	}

	@include media(desktop) {
		width: 1280px;
	}

	@include media(wide-desktop) {
		width: 1600px;
	}

	@include media(desktop, wide-desktop) {
		left: 50%;
		transform: translate(-50%, 0);
	}

	&--invisible {
		top: -60px;

		&--with-notification {
			top: -120px;
		}
	}

	.toolbar {
		height: 60px;
		width: 100%;
		padding: 0 20px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		background-color: white;

		@include media(mobile, wide-mobile) {
			padding: 0;
		}

		&__column {
			display: flex;
		}

		&__item {
			height: 60px;
			display: flex;
			align-items: center;
		}
	}

	.toolbar-notification {
		height: 60px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: #ffd4d4;

		&__text {
			margin: 0;
			font-weight: 700;
			font-size: large;
		}

		&__button {
			&--inline {
				display: inline;
			}
		}
	}
}
