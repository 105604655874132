@import '../../styles/media.scss';

.cart-control {
	position: relative;
	background-color: white;
	max-width: 770px;
	max-height: 94vh;
	overflow-y: auto;

	@include media(mobile, wide-mobile) {
		max-width: none;
		max-height: 100vh;
	}
}

.cart-control__close-button {
	position: absolute;
	top: 0;
	right: 14px;
	font-size: xx-large;
	cursor: pointer;
	color: #d6d6d6;
	transition: color 0.2s ease-in;

	&:hover {
		color: black;
	}
}

.cart-control__form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 25px;
}

.cart-control__form_content {
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;

	@include media(mobile) {
		flex-direction: column-reverse;
		width: 100%;
	}
}

.cart-control__text-container {
	padding-top: 10px;
	min-width: 400px;

	@include media(mobile) {
		min-width: 0;
	}
}

.cart-control__collection {
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.cart-control__collection-name {
	margin: 0;
	color: black;
	cursor: pointer;
	font-size: medium;
}

.cart-control__input-block {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 30px;
}

.cart-control__label {
	font-size: x-small;
	color: grey;
	transition: opacity ease 0.2s;
}

.cart-control__option {
	display: inline-block;
	margin-right: 6px;
	margin-bottom: 6px;
	padding: 6px 12px;
	border-radius: 14px;
	cursor: pointer;
	font-weight: 500;
	font-size: small;
	color: #9e9e9e;
	background-color: #f1f1f1;
	transition: background-color 0.3s ease;

	&:hover {
		background-color: #ebebeb;
	}
}

.cart-control__option--new {
	background: none;
}

.cart-control__option--picked {
	color: white;
	background-color: #1f1f1f;

	&:hover {
		background-color: black;
	}
}

.cart-control__option--unpicked-all {
	color: black;
}

.cart-control__option-title {
	text-align: left;
	cursor: pointer;
}

.cart-control__input-error {
	color: #cc1c2a;
	font-size: small;
	margin-top: 6px;
}

.cart-control__tags-input-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.cart-control__tags-input {
	text-align: start;
}

.cart-control__tags-possible {
	text-align: start;
	margin-top: 6px;
	max-width: 400px;
	max-height: 68px;
	overflow-y: auto;
}

.cart-control__tags-selected {
	text-align: start;
	margin-top: 10px;
	max-width: 400px;
}

.cart-control__image-container {
	margin: 10px;
	margin-right: 26px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.cart-control__source {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	max-width: 200px;
	margin-top: 30px;

	&__image {
		margin-right: 10px;
	}

	&__text {
		font-size: small;
		color: #9e9e9e;
		text-align: start;
	}

	&__link {
		cursor: pointer;
		transition: color ease-in 0.2s;

		&:hover {
			color: black;
		}
	}
}

.cart-control__form-controls {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	width: 100%;
}

.cart-control__form-clear {
	margin-left: 10px;
}

.cart-control__top-color-line {
	position: absolute;
	top: 0;
	width: 100%;
	height: 5px;
	transition: opacity ease-in 3s;

	background: linear-gradient(
		124deg,
		#ff240070,
		#e81d1d70,
		#e8b71d70,
		#e3e81d70,
		#1de84070,
		#1ddde870,
		#2b1de870,
		#dd00f370,
		#dd00f370
	);
	background-size: 2000% 2000%;
	animation: rainbow 50s ease infinite;
}

@keyframes rainbow {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
