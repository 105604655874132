.order-panel {
	width: 100%;
	margin-bottom: 20px;
	display: flex;
	flex-direction: row;
	justify-content: end;
}

.order-panel__controls {
	margin-right: 30px;
}

.order-panel__controls-title {
	margin-right: 12px;
	color: #8f8f8f;
	font-size: small;
}

.order-panel__order-item {
	display: inline-block;
	margin-right: 10px;

	&.order-panel__order-item--picked {
		text-decoration: underline #8f8f8f dashed;
	}
}

.order-panel__order-item-title {
	text-align: left;
	margin: 10px 0;
	color: #9e9e9e;
	cursor: pointer;
	&:hover {
		color: #5a5a5a;
	}
}

.order-panel__order-item--picked > .order-panel__order-item-title {
	color: #6d6d6d;
}
