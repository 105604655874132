.loading-data {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.loading-data--loading-container {
	width: 100%;
}

.loading-data__loader {
	height: 16px;
	width: 100%;

	background-image: linear-gradient(
		90deg,
		#ffffff 0%,
		#ffffff 40%,
		#f1f1f1 49%,
		#f1f1f1 51%,
		#ffffff 60%,
		#ffffff 100%
	);
	animation: loading 3s infinite;
	background-size: 160% 100%;

	&.loading-data__loader--1 {
		animation: loading-1 3s infinite;

		@keyframes loading-1 {
			0% {
				background-position: 0% 50%;
			}
			50% {
				background-position: 100% 50%;
			}
			100% {
				background-position: 0% 50%;
			}
		}
	}

	&.loading-data__loader--2 {
		animation: loading-2 3s infinite;

		@keyframes loading-2 {
			0% {
				background-position: 30% 80%;
			}
			50% {
				background-position: 100% 50%;
			}
			100% {
				background-position: 30% 80%;
			}
		}
	}

	&.loading-data__loader--3 {
		animation: loading-3 3s infinite;

		@keyframes loading-3 {
			0% {
				background-position: 100% 50%;
			}
			50% {
				background-position: 0% 50%;
			}
			100% {
				background-position: 100% 50%;
			}
		}
	}
}
