.collection-page {
	width: 100%;

	&__user-info {
		margin-top: 50px;
		width: 100%;
	}

	&__collection-info {
		margin-top: 50px;
		width: 100%;
		padding-right: 20px;
	}
}

.collection__columns_container {
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	display: flex;
	flex: 0 1 auto;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 80px;
}

.collection__column {
	margin-right: 10px;
	display: flex;
	flex-direction: column;
}

.collection__hcart_container {
	margin-bottom: 50px;
}
