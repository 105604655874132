.confirm-block {
	position: relative;
	background-color: white;
	max-width: 99vw;
	max-height: 94vh;
	overflow-y: auto;

	&__text {
		color: gray;
		font-size: small;
	}
}

.confirm-block__content {
	margin: 20px 40px;
}

.confirm-block__title {
	max-width: 500px;
	font-weight: normal;
}
