.main-page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.title-block {
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;

		&__image {
			width: 512px;
			max-width: 90vw;
		}

		&__description {
			color: gray;
		}
	}

	.description-block {
		height: 70vh;
		max-height: 500px;

		&__text {
			padding: 10px;
		}

		&__word {
			position: relative;
			display: inline-block;
			padding: 2px 6px;
			color: white;

			--before-bg-color: black;
			--before-rotate: rotate(0deg);

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: var(--before-bg-color);
				transform: var(--before-rotate);
				transform-origin: center;
				z-index: -1;
				border-radius: 3px;
			}
		}
	}

	.random-cards-block {
		margin-bottom: 120px;

		&__title {
			color: gray;
			margin-bottom: 60px;
		}
		&__cards {
			display: flex;
			flex-direction: column;
			justify-content: center;
			max-width: 100vw;
		}
		&__card {
			margin-bottom: 50px;
			padding: 10px;
		}
	}

	.contacts-block {
		margin-bottom: 100px;

		&__title {
			color: #c7c7c7;
		}
		&__email {
			color: gray;
		}
	}
}
