@import '../../../styles/media.scss';

.user-info-block {
	display: flex;
	flex-direction: column;

	@include media(mobile) {
		align-items: center;
	}

	.user-info-main {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;

		.user-info {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			&__username {
				font-size: 46px;
				cursor: pointer;
				margin: 0;
				margin-right: 10px;
			}
		}
	}

	.user-info-subscribers {
		display: flex;
		margin-top: 20px;

		&__block {
			margin-right: 40px;
			position: relative;
		}

		&__label {
			margin-top: 10px;
			color: gray;
			cursor: pointer;
			text-decoration: underline #e6e6e6;
			font-size: larger;

			&:hover {
				text-decoration: underline;
			}
		}

		&__count {
			font-size: small;
			margin-left: 6px;
			color: gray;
			position: absolute;
			top: 0;
		}
	}
}
