@import '../../../styles/media.scss';

.profile-settings__form {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-top: 20px;
	padding-bottom: 50px;
	width: 100%;

	@include media(mobile, wide-mobile) {
		align-items: center;
	}

	.profile-settings__input {
		margin-bottom: 30px;
	}

	.profile-settings__submit {
		width: 100%;
	}
}
