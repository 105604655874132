.App {
	text-align: center;
	display: flex;
	justify-content: center;
}

a {
	color: inherit;
	text-decoration: inherit;
}
