.menu-button-container {
	position: relative;
	display: flex;
	align-items: center;
	height: 100%;

	.menu-button {
		padding: 10px 14px;
		height: 100%;
		border: none;
		font-weight: 700;
		font-size: large;
		cursor: pointer;
		color: #4c4c4c;
		background-color: white;
		transition:
			color 0.3s ease,
			background-color 0.3s ease;

		&__extra-info {
			position: absolute;
			top: 8px;
			right: 4px;
			transition: color 0.3s ease;
		}

		&:hover {
			color: white;
			background-color: black;

			.menu-button__extra-info {
				color: white;
			}
		}
	}
}
