.hcart__container {
	width: 100%;
}

.hcart {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	position: relative;
	background-color: white;
	white-space: normal;
}

.hcart__color_background {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	transition:
		height 0.2s ease,
		width 0.2s ease;
}

.hcart__controls-container {
	position: absolute;
	top: 8px;
	right: 6px;
	z-index: 16;
}

.hcart__controls {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.hcart__content_container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.hcart__title_container {
	z-index: 10;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	.hcart__title {
		max-width: 220px;
		text-align: start;
		font-size: 16px;
		font-family: 'Raleway';
		font-weight: 400;
		margin: 20px 0 4px 0;
		cursor: pointer;
		word-break: break-word;
		margin-right: 34px;
	}
}

.hcart__rate {
	max-width: 220px;
	text-align: start;
	font-size: small;
	color: gray;
	margin: 3px 0 18px 0;
	z-index: 10;
	cursor: pointer;
	transition: color 0.3s ease-in;

	&:hover {
		color: #3d3d3d;
	}
}

.hcart__category {
	max-width: 220px;
	text-align: start;
	font-size: small;
	color: gray;
	margin: 3px 0 18px 0;
	z-index: 10;
}

.hcart__notes {
	display: flex;
	align-items: center;
	font-size: small;
	color: gray;
	font-weight: 300;
	cursor: pointer;
	transition: color 0.3s ease-in;

	&-icon {
		opacity: 0.3;
		margin-right: 2px;
		transition: opacity 0.3s ease-in;
	}

	&:hover {
		color: #3d3d3d;
		.hcart__notes-icon {
			opacity: 0.5;
		}
	}
}

.hcart__source {
	display: flex;
	align-items: center;
	font-size: small;
	color: gray;
	font-weight: 300;
	cursor: pointer;
	transition: color 0.3s ease-in;
	margin-top: 10px;

	&__icon {
		opacity: 0.3;
		margin-right: 2px;
		transition: opacity 0.3s ease-in;
	}

	&:hover {
		color: #3d3d3d;
		.hcart__source__icon {
			opacity: 0.5;
		}
	}
}

.hcart__image_container {
	margin: 12px;
	width: 220px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	z-index: 10;

	.hcart__image {
		cursor: pointer;
	}
}

.htags_container {
	margin-bottom: 10px;
	width: 220px;
	z-index: 10;
	transition: opacity 0.3s ease-in;
	text-align: left;

	.htag {
		padding: 4px 6px;
		border-radius: 4px;
		display: inline-block;
		margin-right: 6px;
		margin-bottom: 6px;
		background-color: #f1f1f1;
		transition: background-color 0.3s ease-in;
		cursor: pointer;

		&__name {
			margin: 0;
			font-size: 12px;
			font-family: 'Raleway';
			color: #727272;
			transition: color 0.3s ease-in;
		}

		&:hover {
			background-color: #e4e4e4;

			.htag__name {
				color: #3d3d3d;
			}
		}

		&--picked {
			background-color: #d4d4d4;

			.htag__name {
				color: #000000;
			}
		}
	}

	.htag__extra {
		padding: 4px 6px;
		display: inline-block;
		margin-right: 6px;
		margin-bottom: 6px;
		cursor: pointer;

		.htag__extra_title {
			margin: 0;
			font-size: 12px;
			font-family: 'Raleway';
			color: #727272;
			display: inline;
		}
	}
}

.hcart__emoji-picker-container {
	z-index: 14;
	position: absolute;
	right: 0;
}

.hcart__emoji-panel-container {
	z-index: 12;
	position: absolute;
	bottom: -20px;
	left: 0;
	width: 100%;
}
