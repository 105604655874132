.util-cart {
	width: 100%;
	min-width: 220px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;

	&__button {
		width: 90%;
		height: 180px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 30px;
		background-color: #f8f8f8;
		color: #c4c4c4;
		transition: background-color 0.5s ease-in-out;
		cursor: pointer;

		&:hover {
			color: white;
			background-color: black;
		}

		&__sign {
			font-size: 70px;
			font-weight: 700;
			margin: 0;
		}
	}
}
