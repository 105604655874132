.form-checkbox-input {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	&__block {
		display: flex;
		align-items: center;
		justify-content: center;

		&--left {
			flex-direction: row-reverse;
		}
		&--right {
			flex-direction: row;
		}
	}

	&__label {
		text-align: start;

		&--small {
			margin: 0 2px;
		}
		&--medium {
			margin: 0 6px;
		}

		&--black {
			color: black;
		}
		&--gray {
			color: gray;
		}
	}

	&__input {
		accent-color: black;

		&--small {
			width: 20px;
			height: 20px;
		}
		&--medium {
			width: 30px;
			height: 30px;
		}
	}

	&__error {
		color: #cc1c2a;
		font-size: small;
		margin-top: 6px;
	}
}
