@import '../../../styles/media.scss';

.collection-info {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-bottom: 30px;

	&__row-1 {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 10px;
		width: 100%;

		@include media(mobile) {
			margin-bottom: 20px;
		}

		.collection-info__info {
			display: flex;
			flex-direction: row;
			align-items: flex-end;

			@include media(mobile) {
				flex-direction: column;
				align-items: flex-start;
			}

			.collection-info__title {
				margin: 0;
				margin-right: 20px;
				font-size: x-large;
				font-weight: 700;
				cursor: default;

				@include media(mobile) {
					margin-bottom: 6px;
				}

				&--link {
					cursor: pointer;
					text-decoration: underline lightgray;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	.collection-info__category {
		color: #c4c4c4;
	}

	.collection-info__instruments {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: flex-end;

		@include media(mobile) {
			flex-direction: column;
		}

		.collection-info__instrument {
			cursor: pointer;
			font-weight: 600;
			transition: color 0.2s ease-in;
			color: lightgrey;
			margin: 0;
			margin-left: 10px;

			@include media(mobile) {
				margin-bottom: 6px;
			}

			&:hover {
				color: black;
			}
		}
	}

	.collection-description {
		display: flex;
		justify-content: flex-start;

		&__add-button {
			color: #c4c4c4;
			font-size: small;
			cursor: pointer;
			transition: color 0.2s ease-in;

			&:hover {
				color: black;
			}
		}

		&__text {
			color: #686868;
			font-size: small;
			transition: color 0.2s ease-in;

			&--edit {
				cursor: pointer;
				&:hover {
					color: black;
				}
			}
		}

		&__edit {
			width: 100%;

			.collection-description__edit-controls {
				margin-top: 10px;
				text-align: start;

				.collection-description__clear-button {
					margin-left: 10px;
				}
			}
		}
	}
}
