.emoji-panel {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 20px;
	position: relative;
	width: 100%;
	text-align: left;

	.emoji-panel__util {
		display: inline-block;
		position: relative;
		margin-right: 4px;
		font-size: 14px;

		&__icon {
			cursor: pointer;
			opacity: 0.3;
			padding: 4px 8px;
			transition: opacity ease-in 0.2s;
			border-radius: 14px;
			border: #f3f3f3 solid 1px;
			background-color: #e7e7e7;
			transition: background-color 0.2s ease-in;
			display: flex;
			align-items: center;
			font-size: small;

			&:hover {
				opacity: 0.5;
				background-color: #ffffff;
				border: #c5c5c5 solid 1px;
			}
		}

		.emoji-panel__emoji-picker {
			position: absolute;
			bottom: 0;
			z-index: 9;
		}
	}

	.emoji-panel__emoji-item {
		display: inline-block;
		margin-right: 4px;
		margin-bottom: 2px;

		.emoji-panel__emoji-picker-button {
			cursor: pointer;
			opacity: 0.5;
			padding: 0 8px;
			transition: opacity ease-in 0.2s;

			&:hover {
				opacity: 1;
			}
		}
	}

	.emoji-panel__emoji-picker-container {
		position: absolute;
		bottom: 26px;
		left: 50%;
		transform: translate(-50%, 0);
		z-index: 10;
	}
}
