.close-button {
	position: absolute;
	font-size: xx-large;
	cursor: pointer;
	color: #d6d6d6;
	transition: color 0.2s ease-in;
	z-index: 102;

	&:hover {
		color: black;
	}
}
