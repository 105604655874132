@import '../../styles/media.scss';

.side-panel__container {
	position: relative;
	display: contents;

	.side-panel {
		width: 330px;
		position: relative;
		background-color: white;

		@include media(mobile, wide-mobile) {
			&--closed {
				display: none;
			}
			&--opened {
				display: block;
				position: fixed;
				z-index: 100;
				top: 0;
				left: 0;
				padding: 60px 0;
				padding-left: 40px;
				width: calc(100vw - 40px);
				height: calc(100vh - 120px);
			}

			@include media(wide-mobile) {
				&--opened {
					width: 330px;
				}
			}
		}

		@include media(wide-desktop) {
			width: 300px;
		}

		&__close {
			display: none;

			@include media(mobile, wide-mobile) {
				display: block;
			}
		}

		&__toggle {
			display: none;
			position: fixed;
			bottom: 10px;
			left: 10px;
			z-index: 102;
			height: 30px;
			width: 30px;
			background-color: #e9e9e9;
			border-radius: 50%;
			padding: 6px;
			cursor: pointer;

			@include media(mobile, wide-mobile) {
				display: block;
			}
		}

		.side-panel__content {
			width: inherit;
			overflow: auto;
			position: -webkit-sticky;
			position: sticky;
			top: 70px;
			overflow-y: scroll;

			max-height: calc(100vh - 75px);
			max-height: -o-calc(100vh - 75px); /* opera */
			max-height: -webkit-calc(100vh - 75px); /* google, safari */
			max-height: -moz-calc(100vh - 75px); /* firefox */

			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */

			&::-webkit-scrollbar {
				display: none;
			}

			@include media(mobile, wide-mobile) {
				position: static;
				padding-top: 30px;
			}
		}
	}
}
