.auth-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&__error {
		padding: 10px 10px;
		font-size: small;
		color: #cc1c2a;
		max-width: 260px;
	}

	&__input-block {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin: 12px;
		width: 260px;
	}

	&__submit {
		margin-top: 20px;
	}
}
