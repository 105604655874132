@import '../../../styles/media.scss';

.image-input {
	display: flex;
	flex-direction: column;

	&__label {
		text-align: start;
		font-size: x-small;
		color: grey;
		transition: opacity ease 0.2s;
	}

	&__image-drop-area {
		width: 200px;
		height: 200px;
		border: solid #e6e6e6 1px;
		border-radius: 10px;
		position: relative;
		margin-bottom: 30px;
	}

	&__image-input-label {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 90%;
		color: grey;
		cursor: pointer;
		font-size: small;
	}

	&__file-input {
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
	}

	&__image-url-container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__image-url-input {
		border: none;
		border-bottom: solid #e0e0e0 1px;
		outline: none;
	}

	&__image-preview-container {
		max-height: 260px;
		width: 220px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		margin: 12px;
		position: relative;

		.image-input__image-clear {
			&-sign {
				opacity: 0.9;
			}
		}

		&:hover {
			.image-input__image-clear {
				display: block;
			}
		}

		@include media(mobile, wide-mobile) {
			max-height: 220px !important;

			.image-input__image-clear {
				display: block;
			}
		}
	}

	&__image-preview {
		max-width: 100%;
		height: 100%;
		transition: opacity ease 0.3s;
	}

	&__image-clear {
		cursor: pointer;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: #ffffff99;
		height: 40px;
		width: 40px;
		border-radius: 50%;
		display: none;

		&-sign {
			cursor: pointer;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: xx-large;
		}
	}

	&__error {
		color: #cc1c2a;
		font-size: small;
		margin-top: 6px;
	}
}
