@import '../../../styles/media.scss';

.full-cart {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: start;
	max-width: 1000px;

	@include media(mobile, wide-mobile) {
		max-width: 100vw;
	}

	&__two-blocks {
		display: flex;
		flex-direction: row;
		justify-content: center;
		max-width: 1000px;

		@include media(mobile) {
			max-width: 100vw;
			flex-direction: column-reverse;
			align-items: center;
		}

		@include media(wide-mobile) {
			max-width: 100vw;
		}
	}

	.full-cart__left-block {
		display: flex;
		flex-direction: column;
		max-width: 300px;
		align-items: center;

		.full-cart__image-container {
			margin: 60px 12px 10px 12px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;

			.full-cart__image {
				max-width: 100%;
				max-height: 100%;
				display: block;
				min-width: 200px;
			}
		}
	}

	.full-cart__right-block {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-left: 50px;
		width: 100%;

		@include media(mobile) {
			margin-bottom: 20px;
			padding-left: 0;
		}

		.full-cart__label {
			font-size: small;
			color: #6d6d6d;
			display: block;
			margin-bottom: 4px;
		}

		.full-cart__user-info-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			transition: color ease-in 0.2s;
			color: #6d6d6d;

			&:hover {
				color: #494949;
			}

			.full-cart__user-info-username {
				margin-left: 8px;
				font-size: large;
			}
		}

		.full-cart__two-columns {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-end;
			width: 100%;
		}

		.full-cart__title-block {
			margin: 20px 0 10px 0;

			.full-cart__title {
				width: 100%;
				padding-right: 40px;

				.full-cart__title-display {
					font-size: 34px;
					font-weight: 900;
					margin: 0;
					word-break: break-word;
				}

				.full-cart__title-input {
					width: 100%;
				}
			}

			.full_cart__control-block {
				display: flex;
				align-items: center;

				.full_cart__control {
					margin-right: 4px;
					opacity: 0.3;
					transition: opacity ease-in 0.2s;
					cursor: pointer;

					&:hover {
						opacity: 0.5;
					}

					&--black {
						opacity: 1 !important;
					}
				}
			}
		}

		.full-cart__collection {
			font-size: large;
			font-weight: 500;
			color: #6d6d6d;
			transition: color ease-in 0.2s;

			&:hover {
				color: #494949;
			}
		}

		.full-cart__category {
			color: #6d6d6d;
			font-size: small;
			margin-left: 10px;
		}

		.full-cart__date-created {
			color: #6d6d6d;
			font-size: x-small;
			margin-left: 10px;
		}

		.full-cart__rate {
			margin-top: 50px;
		}

		.full-cart__notes-container {
			margin-top: 50px;
			width: 100%;

			.full-cart__notes {
				.full-cart__note {
					border-radius: 10px;
					background-color: #fafafa;
					padding: 4px 8px;
					width: calc(100% - 16px);
					font-family: inherit;
					font-size: small;
					min-height: 20px;
					margin-bottom: 4px;

					color: #6d6d6d;
					transition: color ease-out 0.2s;

					&:hover {
						color: black;
					}

					a {
						color: #0645ad;
						text-decoration: underline;
					}

					&--spoiler {
						color: #bebebe;
						cursor: pointer;
						display: flex;
						align-items: center;

						.full-cart__note-spoiler-img {
							max-height: 16px;
							width: 16px;
							opacity: 0.3;
							transition: opacity ease-in 0.2s;
							margin-right: 2px;

							&-icon {
								height: 100%;
								width: 100%;
								display: block;
							}
						}

						&:hover {
							background-color: #f2f2f2;
							color: #474747;

							.full-cart__note-spoiler-img {
								opacity: 0.5;
							}
						}
					}
				}
				&-no {
					font-size: xx-small;
					color: #b6b6b6;
				}
			}
		}

		.full-cart__tags-container {
			margin-top: 80px;
			text-align: start;

			.full-cart__tags {
				.full-cart__tag {
					margin-right: 6px;
					margin-bottom: 6px;
					display: inline-block;
				}
				&-no {
					font-size: xx-small;
					color: #b6b6b6;
				}
			}
		}
	}

	&__allow-changing {
		position: relative;
		cursor: pointer;

		&:hover {
			&::before {
				position: absolute;
				top: 50%;
				left: 0;
				width: 20px;
				height: 20px;
				content: '';
				background: url('../../../assets/icons/cart/edit.svg') no-repeat;
				background-size: contain;
				opacity: 0.5;
				transform: translate(-30px, -50%);
			}
		}
	}

	&__edit-panel {
		margin-top: 50px;
		margin-bottom: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		&__checkbox {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 10px;
		}

		&__buttons {
			display: flex;
			justify-content: center;
			align-items: center;
			&__button {
				margin: 0 6px;
			}
		}
	}
}
