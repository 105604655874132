.notes-input-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;

	.notes-input {
		width: 100%;
		margin-top: 6px;

		&__note {
			display: flex;
			flex-direction: row;

			&-input {
				flex-grow: 1;
				padding-right: 20px;
			}
		}

		&__controls {
			width: 40px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&__controls-horizon {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
		}

		&__controls-bottom {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			align-items: flex-start;
		}

		&__control-option {
			max-height: 20px;
			width: 20px;
			opacity: 0.3;
			transition: opacity ease-in 0.2s;
			margin-right: 2px;
			cursor: pointer;

			&:hover {
				opacity: 0.5;
			}

			&--active {
				opacity: 1 !important;
			}
		}

		&__control-option-icon {
			height: 100%;
			width: 100%;
			display: block;
		}
	}
}

.note-input__note {
	margin-bottom: 4px;
	position: relative;

	&-text-input {
		resize: none;
		border: none;
		outline: none;
		border-radius: 10px;
		background-color: #fafafa;
		padding: 4px 8px;
		width: 100%;
		font-family: inherit;
		font-size: small;
		min-height: 20px;
	}

	&__letters-count {
		position: absolute;
		bottom: 2px;
		right: -10px;
		font-size: x-small;
		color: gray;
	}
}
