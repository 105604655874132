.square-button {
	margin: 0;
	margin-left: 10px;
	padding: 8px 14px;
	border-radius: 10px;
	font-weight: 600;
	font-size: medium;
	transition: color 0.2s ease-in;
	background: none;
	border: none;
	cursor: pointer;

	&:hover {
		color: black;
	}

	&--filled {
		color: #646464;
		background-color: #eeeeee;
	}

	&--unfilled {
		color: #919191;
		border-color: #eeeeee;
		border-width: 2px;
		border-style: solid;
	}

	&--is-loading {
		cursor: default;
		background-image: linear-gradient(
			90deg,
			#eeeeee 0%,
			#f5f5f5 40%,
			#fafafa 49%,
			#fafafa 51%,
			#f5f5f5 60%,
			#eeeeee 100%
		) !important;
		animation: loading 5s linear infinite;
		@keyframes loading {
			to {
				background-position: 20vw;
			}
		}
	}
}
