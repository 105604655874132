.single-choice-input {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	&__label {
		font-size: x-small;
		color: grey;
		transition: opacity ease 0.2s;
	}

	&__options {
		text-align: start;
		margin-top: 6px;
		max-height: 68px;
		overflow-y: auto;

		.single-choice-input__option {
			margin-right: 6px;
			margin-bottom: 6px;
			font-size: small;
			display: inline-block;

			&--potential-selected {
				color: black !important;
			}
		}
	}

	&__error {
		color: #cc1c2a;
		font-size: small;
		margin-top: 6px;
	}
}
